<template>
  <section id="faq">
    <div class="mx-auto py-10 px-5 bg-white min-h-sceen">
      <div class="flex flex-col items-center">
        <h2 class="font-bold text-5xl text-[#7014a2] tracking-tight">FAQ</h2>
        <p class="text-neutral-500 text-xl mt-3">Frequenty asked questions</p>
      </div>
      <div class="grid max-w-2xl mx-auto mt-8">
        <div class="py-5 space-y-5 divide-y divide-neutral-200">
          <details class="group pt-5">
            <summary
              class="flex justify-between items-center font-medium cursor-pointer list-none"
            >
              <span>What services does Dextel Sales Solutions provide?</span>
              <span class="transition group-open:rotate-180">
                <svg
                  fill="none"
                  height="24"
                  shape-rendering="geometricPrecision"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <p class="text-neutral-600 mt-3 group-open:animate-fadeIn">
              Dextel offers a range of services including highly targeted
              prospecting, digital marketing, cold calling, appointment setting,
              and lead nurturing to drive your B2B sales growth.
            </p>
          </details>

          <details class="group pt-5">
            <summary
              class="flex justify-between items-center font-medium cursor-pointer list-none"
            >
              <span>How does Dextel's prospecting service work?</span>
              <span class="transition group-open:rotate-180">
                <svg
                  fill="none"
                  height="24"
                  shape-rendering="geometricPrecision"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <p class="text-neutral-600 mt-3 group-open:animate-fadeIn">
              Our prospecting service involves identifying and engaging with
              potential clients from specific industries, providing you with a
              pool of high-potential leads to connect with.
            </p>
          </details>

          <details class="group pt-5">
            <summary
              class="flex justify-between items-center font-medium cursor-pointer list-none"
            >
              <span>Can Dextel help improve my online presence?</span>
              <span class="transition group-open:rotate-180">
                <svg
                  fill="none"
                  height="24"
                  shape-rendering="geometricPrecision"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <p class="text-neutral-600 mt-3 group-open:animate-fadeIn">
              Absolutely! Our digital marketing experts craft strategic email,
              LinkedIn, and social media campaigns to enhance your online
              visibility and engagement.
            </p>
          </details>

          <details class="group pt-5">
            <summary
              class="flex justify-between items-center font-medium cursor-pointer list-none"
            >
              <span>What is "lead nurturing" and why is it important?</span>
              <span class="transition group-open:rotate-180">
                <svg
                  fill="none"
                  height="24"
                  shape-rendering="geometricPrecision"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <p class="text-neutral-600 mt-3 group-open:animate-fadeIn">
              Lead nurturing involves maintaining regular communication with
              leads to build relationships and guide them through the sales
              funnel. It's crucial for converting prospects into loyal
              customers.
            </p>
          </details>

          <details class="group pt-5">
            <summary
              class="flex justify-between items-center font-medium cursor-pointer list-none"
            >
              <span>How does Dextel ensure the quality of cold calling?</span>
              <span class="transition group-open:rotate-180">
                <svg
                  fill="none"
                  height="24"
                  shape-rendering="geometricPrecision"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <p class="text-neutral-600 mt-3 group-open:animate-fadeIn">
              Our qualified cold callers are skilled at initiating meaningful
              conversations with prospects, delivering your message effectively,
              and identifying genuine opportunities.
            </p>
          </details>

          <details class="group pt-5">
            <summary
              class="flex justify-between items-center font-medium cursor-pointer list-none"
            >
              <span
                >What sets Dextel apart from other B2B consultancy firms?</span
              >
              <span class="transition group-open:rotate-180">
                <svg
                  fill="none"
                  height="24"
                  shape-rendering="geometricPrecision"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <p class="text-neutral-600 mt-3 group-open:animate-fadeIn">
              Dextel stands out with its proven track record of success,
              specialized expertise in sales development, and a commitment to
              nurturing a productive work environment for both clients and our
              team.
            </p>
          </details>

          <details class="group pt-5">
            <summary
              class="flex justify-between items-center font-medium cursor-pointer list-none"
            >
              <span
                >Can Dextel adapt to our unique sales process and CRM
                tool?</span
              >
              <span class="transition group-open:rotate-180">
                <svg
                  fill="none"
                  height="24"
                  shape-rendering="geometricPrecision"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <p class="text-neutral-600 mt-3 group-open:animate-fadeIn">
              Yes, we're versatile! Dextel supports and accelerates your
              in-house sales process, regardless of your chosen selling
              methodology or CRM tool.
            </p>
          </details>

          <details class="group pt-5">
            <summary
              class="flex justify-between items-center font-medium cursor-pointer list-none"
            >
              <span>How can I get started with Dextel Sales Solutions?</span>
              <span class="transition group-open:rotate-180">
                <svg
                  fill="none"
                  height="24"
                  shape-rendering="geometricPrecision"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <p class="text-neutral-600 mt-3 group-open:animate-fadeIn">
              Getting started is easy! Simply reach out to us via our contact
              page or give us a call. We'll be thrilled to discuss your specific
              needs and tailor a solution to supercharge your sales journey.
            </p>
          </details>

          <details class="group pt-5">
            <summary
              class="flex justify-between items-center font-medium cursor-pointer list-none"
            >
              <span
                >What kind of results can I expect from partnering with
                Dextel?</span
              >
              <span class="transition group-open:rotate-180">
                <svg
                  fill="none"
                  height="24"
                  shape-rendering="geometricPrecision"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <p class="text-neutral-600 mt-3 group-open:animate-fadeIn">
              Our track record speaks for itself. By leveraging our expertise,
              many clients have experienced increased lead generation, pipeline
              acceleration, and enhanced sales success.
            </p>
          </details>

          <details class="group pt-5">
            <summary
              class="flex justify-between items-center font-medium cursor-pointer list-none"
            >
              <span
                >How does Dextel prioritize a nurturing work environment?</span
              >
              <span class="transition group-open:rotate-180">
                <svg
                  fill="none"
                  height="24"
                  shape-rendering="geometricPrecision"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <p class="text-neutral-600 mt-3 group-open:animate-fadeIn">
              At Dextel, we believe in fostering a positive and growth-oriented
              workplace for our team, which translates to dedicated service and
              support for our clients' success.
            </p>
          </details>
        </div>
      </div>
    </div>
  </section>
</template>
