import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "./assets/css/index.css";
import VueSmoothScroll from "vue3-smooth-scroll";

createApp(App)
  .use(router)

  .use(VueSmoothScroll)
  .mount("#app");
