<template>
  <section id="about-us" class="px-3 py-10 md:p-20 bg-gray-100">
    <div class="rounded-3xl p-5 md:p-20 hover:shadow-md bg-white">
      <h2
        data-aos="fade-right"
        data-aos-once="true"
        class="text-3xl border-b-2 border-gray-100 mx-5 lg:mx-40 text-center pb-5 text-[#7014a2] font-bold capitalize"
      >
        About Us
      </h2>
      <p
        data-aos="fade-down"
        data-aos-once="true"
        data-aos-delay="300"
        class="text-md font-medium sm:block my-10 text-gray-500 lg:mx-20 text-center"
      >
        Dextel Sales Solutions is a leading B2B consultancy firm specializing in
        sales development and lead generation activities. Our team of dedicated
        experts brings together a unique blend of skills, experience, and
        passion for helping businesses thrive. With a proven track record of
        success, we have become a reliable choice for companies seeking to
        amplify their sales potential.
      </p>
      <div class="grid grid-cols-1 lg:grid-cols-2 justify-around items-center">
        <div class="space-y-5 bg-gray-100 p-5 rounded-3xl hover:shadow">
          <h3
            class="font-semibold text-lg text-center md:text-start m-auto text-gray-700"
          >
            Our Approach
          </h3>
          <p
            data-aos="fade-down"
            data-aos-once="true"
            data-aos-delay="300"
            class="text-md font-medium sm:block text-gray-800"
          >
            At Dextel, we believe that every business is unique. That's why our
            approach is tailored to meet the specific needs and goals of each
            client. We pride ourselves on understanding your industry, target
            audience, and sales objectives, allowing us to create customized
            strategies that deliver exceptional results.
          </p>
          <p
            data-aos="fade-down"
            data-aos-once="true"
            data-aos-delay="300"
            class="text-md font-medium sm:block text-gray-800"
          >
            Our services cover a wide spectrum of sales operations, including
            highly targeted prospecting, digital marketing, cold calling,
            appointment setting, and lead nurturing. By leveraging cutting-edge
            techniques and innovative methodologies, we ensure that your
            business gains a competitive edge in today's dynamic marketplace.
          </p>
        </div>
        <div class="flex justify-center">
          <img
            lazy
            data-aos="fade-up"
            data-aos-once="true"
            :src="require('@/assets/img/team.png')"
            class="transition duration-500 origin-bottom hover:rotate-12"
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
</template>
