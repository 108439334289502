<template>
  <section id="schedule-a-demo" class="px-5 pb-10 md:p-20 bg-gray-100">
    <div
      class="flex flex-col space-y-10 justify-center items-center rounded-3xl bg-white py-10 md:py-20 px-5 md:px-10"
    >
      <h2
        class="text-2xl md:text-3xl text-[#7014a2] text-center font-bold capitalize"
      >
        Ready to embark on a sales journey that transforms your business?
      </h2>
      <a
        data-aos="fade-up"
        data-aos-once="true"
        target="_blank"
        data-aos-delay="700"
        href="https://calendly.com/dextelgroup/30min"
        class="text-sm font-semibold text-center rounded-full hover:shadow-md hover:shadow-[#9558b6]/50 transition duration-300 w-fit px-8 xl:px-10 py-4 mt-2 bg-gradient-to-r from-[#9558b6] to-[#7014a2] text-white"
      >
        Get Started
      </a>
    </div>
  </section>
</template>
