<template>
  <nav id="scroll-to-top" class="sticky z-40 w-full text-neutral-800 shadow-md">
    <div class="flex flex-col max-w-screen-xl px-8 mx-auto lg:items-center lg:justify-between lg:flex-row">
      <div class="flex justify-between lg:flex-row items-center space-x-4 xl:space-x-8">
        <div>
          <router-link to="/">
            <img :src="require('@/assets/img/logo.png')" class="w-20 xl:w-28" alt="Logo" />
          </router-link>
          
        </div>
        <button class="rounded-lg lg:hidden focus:outline-none focus:shadow-outline" @click="open = !open">
          <svg v-if="!open" fill="currentColor" width="24" height="24" viewBox="0 0 24 24"
            class="material-design-icon__svg">
            <path d="M21,8H3V6H21M9,13H21V11H9M9,18H21V16H9"><!----></path>
          </svg>
          <svg v-else fill="currentColor" width="24" height="24" viewBox="0 0 24 24" class="material-design-icon__svg">
            <path
              d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z">
            </path>
          </svg>
        </button>
      </div>
      <div :class="[open ? 'flex' : 'hidden lg:flex']" class="space-x-3">
        <ul :class="[open ? 'flex gap-2' : 'hidden lg:flex']"
          class="w-full h-auto flex flex-col flex-grow lg:items-center pb-4 lg:pb-0 lg:justify-end lg:flex-row origin-top duration-300 space-y-3 lg:space-y-0">
          <li class="w-fit">
            <a class="md:px-4 text-sm bg-transparent whitespace-nowrap rounded-lg text-[#666666] hover:text-gray-900 focus:outline-none focus:shadow-outline"
              href="/#about-us" v-smooth-scroll="{ duration: 1000 }">
              About Us
            </a>
          </li>
          <li class="w-fit">
            <a class="md:px-4 text-sm bg-transparent whitespace-nowrap rounded-lg text-[#666666] hover:text-gray-900 focus:outline-none focus:shadow-outline"
              href="#expertise" v-smooth-scroll="{ duration: 1000 }">
              Our Expertise
            </a>
          </li>
          <li class="w-fit">
            <a class="md:px-4 text-sm bg-transparent whitespace-nowrap rounded-lg text-[#666666] hover:text-gray-900 focus:outline-none focus:shadow-outline"
              href="#mission" v-smooth-scroll="{ duration: 1000 }">
              Our Mission
            </a>
          </li>
          <li class="w-fit">
            <a class="md:px-4 text-sm bg-transparent whitespace-nowrap rounded-lg text-[#666666] hover:text-gray-900 focus:outline-none focus:shadow-outline"
              href="#faq" v-smooth-scroll="{ duration: 1000 }">
              FAQ
            </a>
          </li>
          <li class="w-fit">
            <a href="https://calendly.com/dextelgroup/30min" target="_blank" :class="[open ? 'px-4 py-2' : ' px-8 py-4']"
              class="text-sm whitespace-nowrap font-semibold text-center rounded-full hover:shadow-md hover:shadow-[#9558b6]/50 transition duration-300 xl:px-10 bg-gradient-to-r from-[#9558b6] to-[#7014a2] text-white">
              Book a Demo
            </a>
          </li>
          <!-- <li class="relative group">
            <button
              class="md:px-4 text-sm bg-transparent rounded-lg text-[#666666] hover:text-gray-900 focus:outline-none focus:shadow-outline flex items-center"
              @click="toggleDropdown">
              <span>Services</span>
              <img lazy :src="dropdownNavbar ? require('@/assets/img/icons/up.svg') : require('@/assets/img/icons/down.svg')"
                class="w-10" alt="toggle-icon" />
            </button>
            <transition name="transform-fade-down">
              <ul v-if="dropdownNavbar"
                class="flex lg:absolute flex-col max-w-42 py-1 lg:bg-white rounded-md lg:shadow-md pl-2 lg:pl-0">
                <li>
                  <a href="#" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100">Exchange</a>
                </li>
              </ul>
            </transition>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      open: false,
      dropdownNavbar: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.dropdownNavbar = !this.dropdownNavbar;
    },
    closeDropdown(event) {
      if (!this.$el.contains(event.target)) {
        this.dropdownNavbar = false;
      }
    },
  },
  mounted() {
    // Attach a global click event listener to window
    window.addEventListener("click", this.closeDropdown);
  },
  beforeUnmount() {
    // Remove the event listener when component is destroyed
    window.removeEventListener("click", this.closeDropdown);
  },
};
</script>
