<template>
  <div class="sticky flex justify-end mr-10">
    <a
      href="#scroll-to-top"
      v-smooth-scroll="{ duration: 1000 }"
      class="w-fit fill-[#7014a2]"
    >
      <svg
        class="animate-bounce"
        height="54"
        viewBox="0 0 24 24"
        width="54"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M13,10.4142136 L13,17 L11,17 L11,10.4142136 L8.70710678,12.7071068 L7.29289322,11.2928932 L12,6.58578644 L16.7071068,11.2928932 L15.2928932,12.7071068 L13,10.4142136 Z"
          fill-rule="evenodd"
        />
      </svg>
    </a>
  </div>
</template>
