<template>
  <section>
    <footer class="sticky bottom-0 bg-[#7014a2] rounded-t-2xl py-10 px-5 md:px-20 text-white">
      <div class="grid grid-cols-1 md:grid-cols-2 my-5 gap-10">
        <div class="flex flex-wrap font-semibold gap-3">
          <span>&copy; DEXTEL SALES {{ currentYear }} </span>
          <a href="#">Privacy Policy</a>
          <a href="#">Terms of use</a>
          <!-- <router-link to="/privacy-policy">Privacy Policy</router-link>
    <router-link to="/terms-of-service">Terms of use</router-link> -->
        </div>
        <!-- Socials -->
        <div class="flex flex-wrap items-end md:justify-end gap-6">
          <a href="https://www.linkedin.com/company/dextel-sales-solutions/" target="_blank">
            <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="mask0_11_2201" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="30"
                height="31">
                <g clip-path="url(#clip0_11_2201)">
                  <path
                    d="M27.8364 0.168945H2.16362C0.968628 0.168945 0 1.13757 0 2.33257V28.0053C0 29.2003 0.968628 30.1689 2.16362 30.1689H27.8364C29.0314 30.1689 30 29.2003 30 28.0053V2.33257C30 1.13757 29.0314 0.168945 27.8364 0.168945V0.168945ZM10.6412 22.8447H6.98799V11.854H10.6412V22.8447ZM8.8147 10.3533H8.79089C7.565 10.3533 6.77216 9.50938 6.77216 8.4547C6.77216 7.37621 7.58926 6.55566 8.83896 6.55566C10.0887 6.55566 10.8577 7.37621 10.8815 8.4547C10.8815 9.50938 10.0887 10.3533 8.8147 10.3533ZM23.8138 22.8447H20.1611V16.965C20.1611 15.4873 19.6321 14.4796 18.3103 14.4796C17.3012 14.4796 16.7001 15.1593 16.436 15.8155C16.3394 16.0504 16.3158 16.3786 16.3158 16.707V22.8447H12.6629C12.6629 22.8447 12.7107 12.8852 12.6629 11.854H16.3158V13.4102C16.8013 12.6613 17.6699 11.5961 19.6081 11.5961C22.0116 11.5961 23.8138 13.1669 23.8138 16.5427V22.8447Z"
                    fill="white" />
                </g>
              </mask>
              <g mask="url(#mask0_11_2201)">
                <circle cx="15" cy="15.1689" r="15" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_11_2201">
                  <rect width="30" height="30" fill="white" transform="translate(0 0.168945)" />
                </clipPath>
              </defs>
            </svg>
          </a>
          <a href="mailto:admin@dextelgroup.com" target="_blank">
            <svg class="fill-white" width="30" height="31" version="1.1" viewBox="0 0 32 32" xml:space="preserve"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Style_2">
                <g>
                  <path d="M16,0C7.163,0,0,7.163,0,16c0,8.836,7.163,16,16,16s16-7.164,16-16C32,7.163,24.837,0,16,0z"
                    fill="#FFFFFF" />
                </g>
                <g>
                  <polygon fill="#7014a2" points="6.518,21.815 11.707,15.291 6.518,12.119   " />
                  <polygon fill="#7014a2" points="19.5,15.746 15.989,17.908 12.472,15.758 7.11,22.5 24.867,22.5   " />
                  <polygon fill="#7014a2" points="15.988,16.864 25.482,11.017 25.482,9.5 6.518,9.5 6.518,11.076   " />
                  <polygon fill="#7014a2" points="20.263,15.276 25.482,21.843 25.482,12.062   " />
                </g>
              </g>
            </svg>
          </a>
        </div>
      </div>
    </footer>
  </section>
</template>
<script>
// import SubscriptionForm from '@/components/SubscriptionForm.vue';
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
      loading: false,
    };
  },
  components: {
    // SubscriptionForm,
  },
};
</script>
