<template>
  <div class="min-h-screen font-sans antialiased relative">
    <div class="relative">
      <div class="">
        <NavbarSection />
        <HeroSection />
      </div>
      <AboutUs />
      <ExpertiseSection />
      <MissionSection />
      <ScheduleDemo />
      <FaqSection />
      <ScrollBar />
      <FooterSection />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeroSection from "@/components/HeroSection.vue";
import MissionSection from "@/components/MissionSection.vue";
import ScheduleDemo from "@/components/ScheduleDemo.vue";
import ExpertiseSection from "@/components/ExpertiseSection.vue";
import AboutUs from "@/components/AboutUs.vue";
import ScrollBar from "@/components/ScrollBar.vue";
import FooterSection from "@/components/FooterSection.vue";
import FaqSection from "@/components/FaqSection.vue";
import NavbarSection from "@/components/NavbarSection.vue";

export default {
  name: "HomeView",
  components: {
    HeroSection,
    MissionSection,
    ScheduleDemo,
    ExpertiseSection,
    AboutUs,
    ScrollBar,
    FooterSection,
    FaqSection,
    NavbarSection,
  },
};
</script>
