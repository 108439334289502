<template>
  <section id="mission" class="px-3 py-10 md:p-20 bg-gray-100">
    <div class="rounded-3xl p-5 md:p-20 hover:shadow-md bg-white">
      <h2
        data-aos="fade-right"
        data-aos-once="true"
        class="text-3xl border-b-2 border-gray-100 md:mx-40 text-center pb-5 text-[#7014a2] font-bold capitalize"
      >
        Our Mission
      </h2>
      <div class="grid grid-cols-1 md:grid-cols-2 justify-around items-center">
        <div class="flex justify-center">
          <img
            lazy
            data-aos="fade-up"
            data-aos-once="true"
            :src="require('@/assets/img/mission-1.png')"
            class="transition duration-500 origin-bottom hover:-rotate-12"
            alt=""
          />
        </div>
        <div class="space-y-5 bg-gray-100 px-5 py-10 rounded-3xl hover:shadow">
          <p
            data-aos="fade-down"
            data-aos-once="true"
            data-aos-delay="300"
            class="text-xl font-medium sm:block text-gray-800"
          >
            Dextel's mission is to increase their clients' exposure and optimize
            their sales process while fostering a nurturing work environment.
          </p>
          <ul class="text-gray-500 space-y-6">
            <li>
              🚀
              <span class="font-bold text-gray-700">Exposure Amplified: </span
              >We're dedicated to boosting your visibility and reach in the
              competitive landscape.
            </li>
            <li>
              🛠️
              <span class="font-bold text-gray-700"
                >Optimized Sales Process: </span
              >Experience efficiency and effectiveness as we fine-tune your
              sales process.
            </li>
            <li>
              🌱
              <span class="font-bold text-gray-700"
                >Nurturing Environment: </span
              >Join us in a work environment where growth is nurtured.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>
