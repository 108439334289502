<template>
  <!-- 
<div class="absolute top-0 left-0 w-full h-screen sm:h-[225vh] lg:h-screen cover-gradient-2 sm:cover-gradient">
      </div> -->
  <section id="hero" class="w-full py-20">
    <div class="relative max-w-screen-xl px-4 sm:px-8 mx-auto grid grid-cols-12 gap-x-6 overflow-hidden">
      <div class="col-span-12 lg:col-span-6 xl:mt-10 space-y-4 sm:space-y-6 px-6 text-center sm:text-left">
        <span data-aos="fade-right" data-aos-once="true"
          class="text-base text-[#7014a2] font-semibold uppercase tracking-tight">Welcome to Dextel Sales Solutions</span>
        <h1 data-aos="fade-right" data-aos-once="true"
          class="text-[3rem] text-gray-800 sm:text-5xl xl:text-6xl leading-tight font-bold capitalize sm:pr-8 xl:pr-10">
          Your Partner in
          <span class="text-[#7014a2] font-black"> Elevating B2B</span> Sales
          Success.
        </h1>
        <p data-aos="fade-down" data-aos-once="true" data-aos-delay="300"
          class="font-normal hidden sm:block text-gray-500">
          We specialize in sales development and lead generation, propelling
          your success. With a proven track record, we excel at opening doors,
          nurturing leads, and accelerating pipelines.
        </p>
        <div data-aos="fade-up" data-aos-once="true" data-aos-delay="700"
          class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 mt-2">
          <a href="https://calendly.com/dextelgroup/30min" target="_blank"
            class="text-sm font font-semibold text-center rounded-full hover:shadow-md hover:shadow-[#9558b6]/50 transition duration-300 px-8 xl:px-10 py-4 mt-2 bg-gradient-to-r from-[#9558b6] to-[#7014a2] text-white">
            Schedule a Call
          </a>
        </div>
      </div>
      <div class="hidden sm:block col-span-12 lg:col-span-6">
        <div class="flex justify-center">
          <img lazy data-aos="fade-up" data-aos-once="true" :src="require('@/assets/img/chart2.png')"
            class="mt-4 transition duration-500 origin-bottom hover:rotate-6 cursor-pointer" alt="hero-image" />
        </div>
      </div>
      <img lazy data-aos="fade-up" data-aos-delay="300" :src="require('@/assets/img/pattern/ellipse-1.png')"
        class="hidden sm:block absolute bottom-12 xl:bottom-16 left-4 xl:left-0 w-6" />
      <img data-aos="fade-up" data-aos-delay="300" :src="require('@/assets/img/pattern/ellipse-2.png')"
        class="hidden sm:block absolute top-4 sm:top-10 right-64 sm:right-96 xl:right-[32rem] w-6" />
      <img lazy data-aos="fade-up" data-aos-delay="300" :src="require('@/assets/img/pattern/ellipse-3.png')"
        class="hidden sm:block absolute bottom-56 right-24 w-6" />
    </div>
  </section>
</template>
